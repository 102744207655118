import React from 'react'
import get from 'lodash/get'
import { Helmet } from 'react-helmet'
import Layout from '../components/layout'
import {graphql} from "gatsby"
import NavigationList from '../components/navigation-list'


class HomeIndex extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const [contact] = get(this, 'props.data.allContentfulKontakt.edges')
    return (
      <Layout location={this.props.location} showAddress={true} contactData={contact.node}>
        <Helmet title={siteTitle}>
          <meta name="viewport" content="initial-scale=1, maximum-scale=1" />
        </Helmet>
        <NavigationList links={[
          { path: '/aktuell/', text: 'AKTUELL'},
          { path: '/ausstellung/', text: 'AUSSTELLUNGEN'},
          { path: '/werk/', text: 'WERKE'},
            { path: '/projekt/', text: 'PROJEKTE'},
          { path: '/publikation/', text: 'PUBLIKATIONEN'},
          { path: '/curriculum/', text: 'CURRICULUM'}
        ]}></NavigationList>
      </Layout>
    )
  }
}

export default HomeIndex

export const pageQuery = graphql`
  query HomeQuery {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulKontakt(
      filter: { contentful_id: { eq: "1rCvAfQs6L8n0buVr2XP27" } }
    ) {
      edges {
          node {
            name
            title
            email
          }
        }
    }
  }
`
